<template>
  <div class="schedule-list wrap px-3 py-5">
    <div class="d-flex flex-wrap align-center">
      <div class="h2 d-flex flex-wrap">
        <div class="me-2">Schedule:</div>
        <div class="secondary--text">
          {{ new Date(this.dateFrom).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) }} -
          {{ new Date(this.dateTo).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}
        </div>
      </div>
      <div>
        <v-btn color="gray" @click="week--, getWeekDate()" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn color="gray" @click="week++, getWeekDate()" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn to="/schedule-edit" width="70" height="30" outlined>
        <img class="filter-black me-1" src="@/assets/icon/calendar.svg" /> Edit
      </v-btn>
    </div>
    <div class="d-flex flex-wrap">
      <div class="d-flex align-center">
        <div class="h8 text--text">Course:</div>
        <v-autocomplete
          class="pt-0 schedule-list_filter text-no-wrap"
          v-model="course"
          :items="courseList"
          @change="getData"
          append-icon="mdi-chevron-down mdi-18px"
          color="blue"
          item-text="title"
          item-value="id"
          solo
          hide-details
          dense
        >
          <template v-slot:selection="data">{{ data.item.title }}</template>
        </v-autocomplete>
      </div>
      <div class="d-flex align-center">
        <div class="h8 text--text">Student:</div>
        <v-autocomplete
          class="pt-0 schedule-list_filter text-no-wrap"
          v-model="student"
          :items="studentList"
          @change="getData"
          append-icon="mdi-chevron-down mdi-18px"
          color="blue"
          item-text="first_name"
          item-value="id"
          solo
          hide-details
          dense
        >
          <template v-slot:selection="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>
          <template v-slot:item="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>
        </v-autocomplete>
      </div>
    </div>
    <v-row class="mt-sm-10 mt-5">
      <v-col cols="12" sm="6" v-for="item in data" :key="item.id">
        <div class="h7 mb-3">
          {{ new Date(item.date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' }) }}
        </div>
        <div v-if="!item.windows.length" class="h7 accent text--text time-card">No time slots for this day</div>
        <div v-for="j in item.windows" :key="j.id">
          <v-card
            tile
            flat
            v-if="j.booking"
            class="time-card-full"
            :style="j.booking.status == 'finished' ? 'border-color: #FC5D59' : 'border-color: #6B6FE0'"
          >
            <div class="mb-3 d-flex">
              <div class="h7 text--text text-capitalize">
                {{ j.begin_time }} - {{ j.end_time }} <span v-if="j.booking.status == 'finished'">({{ j.booking.status }})</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn v-if="j.booking.status != 'finished'" @click="openModal(j.booking.booking_lesson_id)" icon class="px-0">
                <v-img height="16" max-width="16" contain src="@/assets/icon/edit.svg"></v-img>
              </v-btn>
            </div>
            <div class="mb-2 d-flex align-center">
              <v-avatar size="24" color="primary">
                <v-img v-if="j.booking.student.picture" :src="j.booking.student.picture"></v-img>
                <v-icon v-else color="white">mdi-account mdi-18px</v-icon>
              </v-avatar>
              <div class="h4 ms-2">{{ j.booking.student.first_name }} {{ j.booking.student.last_name }}</div>
            </div>
            <div>{{ j.booking.course.title }}: {{ j.booking.lesson.title }}</div>
          </v-card>
          <div v-else-if="!j.available" class="h7 time-card accent">
            {{ j.begin_time }} - {{ j.end_time }} <span class="h8 text--text ps-2">Busy</span>
          </div>
          <div v-else class="h7 time-card">{{ j.begin_time }} - {{ j.end_time }}</div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="modal" width="568">
      <v-card class="pa-6">
        <div class="h2 mb-4">Edit lesson</div>
        <div class="h10 mt-n2">Date<span class="error--text">*</span></div>
        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="field44"
              v-model="date"
              append-icon="mdi-calendar-month-outline"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              color="secondary"
              height="44"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            :min="new Date().toISOString().substr(0, 10)"
            v-model="date"
            no-title
            @input="(menu = false), getDateList()"
          ></v-date-picker>
        </v-menu>
        <div class="h10 mt-n2">Time<span class="error--text">*</span></div>
        <v-select
          class="field44"
          v-model="time"
          :items="dateList"
          item-text="begin_time"
          item-value="id"
          :item-disabled="(item) => !item.available"
          append-icon="mdi-chevron-down mdi-18px"
          outlined
          dense
          :disabled="!date"
          color="secondary"
          height="44"
        >
          <template v-slot:selection="{ item }">{{ item.begin_time }} - {{ item.end_time }}</template>
          <template v-slot:item="{ item }">{{ item.begin_time }} - {{ item.end_time }}</template>
        </v-select>
        <div class="text-right">
          <v-btn @click="modal = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="editLesson" :disabled="!time" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.$route.query['date-from'] ? this.getData() : this.getWeekDate();
    this.$store.dispatch('getCourseList');
    this.$store.dispatch('getStudentList');
  },
  data() {
    return {
      modal: false,
      menu: false,
      week: 0,
      dateFrom: this.$route.query['date-from'] || '',
      dateTo: this.$route.query['date-to'] || '',
      course: this.$route.query.course || 'all',
      student: this.$route.query.student || 'all',
      bookingId: '',
      date: null,
      time: '',
    };
  },
  methods: {
    async getData() {
      const data = new Object();
      data.dateFrom = this.dateFrom;
      data.dateTo = this.dateTo;
      data.course = this.course;
      data.student = this.student;
      await this.$store.dispatch('getScheduleList', data);
    },
    getWeekDate() {
      const data = this.$route.query['date-from'] ? new Date(this.$route.query['date-from']) : new Date();
      // 604800000 - this is 1 week in ms
      const week = 604800000 * this.week;
      // 86400000 - this 24 hours in ms
      const s = data.getTime() - (data.getDay() - 1) * 86400000 + week;
      // 518400000 - this is 6 days in ms
      const e = s + 518400000;
      const startWeek = `${new Date(s).getFullYear()}-${new Date(s).getMonth() + 1}-${new Date(s).getDate()}`;
      const endWeek = `${new Date(e).getFullYear()}-${new Date(e).getMonth() + 1}-${new Date(e).getDate()}`;
      this.dateFrom = startWeek;
      this.dateTo = endWeek;
      this.getData();
    },
    openModal(id) {
      this.bookingId = id;
      this.modal = true;
    },
    async getDateList() {
      await this.$store.dispatch('getAvailableWindowsList', this.date);
    },
    async editLesson() {
      const data = new Object();
      data.window = this.time;
      data.date = this.date;
      await this.$store.dispatch('editStudentLesson', { id: this.bookingId, data: data }).then(() => {
        this.bookingId = '';
        this.date = null;
        this.time = '';
        this.modal = false;
        this.$store.dispatch('getStudentSchedule');
        this.$store.dispatch('getStudentCourses');
      });
    },
  },
  watch: {
    courseList() {
      setTimeout(() => {
        this.courseList.unshift({ id: 'all', title: 'All' });
      }, 100);
    },
    studentList() {
      setTimeout(() => {
        this.studentList.unshift({ id: 'all', first_name: 'All', last_name: '' });
      }, 100);
    },
    modal(val) {
      val || (this.date = null);
    },
  },
  computed: {
    data() {
      return this.$store.getters.scheduleList;
    },
    courseList() {
      return this.$store.getters.courseList;
    },
    studentList() {
      return this.$store.getters.studentList;
    },
    dateList() {
      return this.$store.getters.availableList.windows;
    },
  },
  destroyed() {
    this.$store.dispatch('setScheduleList', []);
    this.$store.dispatch('setCourseList', [{ id: 'all', title: 'All' }]);
    this.$store.dispatch('setStudentList', [{ id: 'all', first_name: 'All', last_name: '' }]);
    this.$store.dispatch('setAvailableWindowsList', { windows: [] });
  },
};
</script>

<style lang="scss">
.schedule-list {
  .h2 {
    min-width: 370px;
  }
  &_filter {
    min-width: 70px;
    max-width: fit-content !important;
  }
  .time-card-full {
    border-left: 5px solid;
    padding: 12px;
    background: var(--v-accent-base);
    margin-bottom: 12px;
  }
  .time-card {
    padding: 12px;
    padding-left: 17px;
    margin-bottom: 12px;
    background: url('../assets/img/field.svg') rgba(196, 196, 196, 0.3);
    background-size: cover;
  }
  @media screen and (max-width: 450px) {
    .h2 {
      min-width: initial;
    }
  }
}
</style>
